/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import { H1 } from '../components/Heading'
import Avatar from '../components/Avatar'
import Link, { ExternalLink } from '../components/Link'

import madsobel from '../images/madsobel.jpg'

const StyledParagraph = styled.p`
  line-height: 2;
`

function IndexPage() {
  return (
    <Layout>
      <H1 centered>Hello, World!</H1>
      <Avatar src={madsobel} alt="Mads Obel" />
      <br />
      <StyledParagraph>
        My name is Mads Obel, I am a software developer living in Copenhagen,
        Denmark.
      </StyledParagraph>
      <StyledParagraph>
        This is my personal little playground, where I list my{' '}
        <Link to="/projects">projects</Link>. I also have a small{' '}
        <Link to="/blog">blog</Link> where you can read about cool stuff I am
        interested in.
      </StyledParagraph>
      <StyledParagraph>
        If you do want to get in contact with me, please do reach out to me on{' '}
        <ExternalLink
          href="https://twitter.com/madsobel"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </ExternalLink>{' '}
        or{' '}
        <ExternalLink
          href="https://www.linkedin.com/in/madsobel/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </ExternalLink>
      </StyledParagraph>
    </Layout>
  )
}

export default IndexPage
