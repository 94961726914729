import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledAvatar = styled.img`
  width: 12em;
  height: 12em;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
`

function Avatar({ src, alt }) {
  return <StyledAvatar src={src} alt={alt} />
}

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
}

Avatar.defaultProps = {
  alt: ''
}

export default Avatar
